import { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Alert from 'react-bootstrap/Alert';
import "./invitados.css";


function TableVenues() {

    const [show, setShow]                                       = useState(false);
    const [showDelete, setShowDelete]                           = useState(false);
    const [showAdd, setShowAdd]                                 = useState(false);
    const [showQr, setShowQr]                                   = useState(false);
    const [displayQr, setDisplayQr]                             = useState(false);
    const [deleteId, setDeleteId]                               = useState(false);
    const [items, setItems]                                     = useState([]);
    const [showAlert, setShowAlert]                             = useState(false);
    const [showAlertDelete, setShowAlertDelete]                 = useState(false);
  

    // ---------- nuevo invitados ------------
    const [selectEventos, setSelectEventos]                     = useState([]);
    const [selectHorarios, setSelectHorarios]                   = useState(["NA"]);
    const [inputNombre, setInputNombre]                         = useState("");
    const [inputEvento, setInputEvento]                         = useState("");
    const [inputEmail, setInputEmail]                           = useState("");
    const [inputHorario, setInputHorario]                       = useState("");
    const [inputClave, setInputClave]                           = useState("");
    const [showHorariosDiv, setShowHorariosDiv]                 = useState(false);
    const [statusBtnSubmit, setStatusBtnSubmit]                 = useState(false);

    // ----- edit invitados -----------
    const [inputIdEdit, setInputIdEdit]                         = useState("");
    const [inputNombreEdit, setInputNombreEdit]                 = useState("");
    const [inputEventoEdit, setInputEventoEdit]                 = useState("");
    const [inputClaveEdit, setInputClaveEdit]                   = useState("");
    const [inputEmailEdit, setInputEmailEdit]                   = useState("");
    const [inputHorarioEdit, setInputHorarioEdit]               = useState("");
    const [inputStatusEdit, setInputStatusEdit]                 = useState("");
    const [showHorariosDivEdit, setShowHorariosDivEdit]         = useState(false);
    const [selectHorariosEdit, setSelectHorariosEdit]           = useState([]);


    const handleClose = () => setShow(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseQr = () => setShowQr(false);
    const handleCloseAdd = () => setShowAdd(false);


    //------------------------- mapas -------------------------
    //const position = {lat: 53.54992, lng: 10.00678};
      
    const handleShow = (item_id) => {
        setShow(true);
    
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('api/get-invitado/'+item_id)
            .then(response => {
                setInputIdEdit(response.data.data[0].id)
                setInputNombreEdit(response.data.data[0].nombre_completo)
                setInputClaveEdit(response.data.data[0].clave_invitado)
                setInputEmailEdit(response.data.data[0].email)
                setInputEventoEdit(response.data.data[0].id_evento)
                setInputHorarioEdit(response.data.data[0].id_horario)
                setInputStatusEdit(response.data.data[0].status)

                if(response.data.data[0].id_horario){
                    setShowHorariosDivEdit(true)
                }
                else{
                    setShowHorariosDivEdit(false)
                }

                instance.get('api/get-horarios/'+response.data.data[0].id_evento)
                .then(responsex => {
                    setSelectHorariosEdit(responsex.data.data)
                })
            })

            

    }

    const handleShowAdd = async () => {

        randomPassword();
        setShowAdd(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const handleDelete = () => {
        deleteInvitado(deleteId)
    }

    const getDataHorarios = async (id) => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(

       

        await instance.get('api/get-evento/'+id)
        .then(responsex => {
            if(responsex.data.data[0]){
                if(responsex.data.data[0].horarios_especificos>0){
                    setShowHorariosDiv(true)
                }
                else{
                    setShowHorariosDiv(false)
                }
            }
            else{
                setShowHorariosDiv(false)
            }
            
        })

            await instance.get('api/get-horarios/'+id)
            .then(response => {
                setSelectHorarios(response.data.data)
            })

        //)
    }

    const getDataHorariosEdit = async (id) => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(

        await instance.get('api/get-evento/'+id)
        .then(responsex => {
            if(responsex.data.data[0]){
                if(responsex.data.data[0].horarios_especificos>0){
                    setShowHorariosDivEdit(true)
                }
                else{
                    setShowHorariosDivEdit(false)
                }
            }
            else{
                setShowHorariosDivEdit(false)
            }
            
        })

            await instance.get('api/get-horarios/'+id)
            .then(response => {
                console.log(response.data.data)
                setSelectHorariosEdit(response.data.data)
            })

        //)
    }

    const getData= async () => {

            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
            
            await instance.get('api/get-eventos')
            .then(response2 => {
                setSelectEventos(response2.data)
            })

            await instance.get('api/get-invitados')
            .then(response => {
                setItems(response.data)
            })

    }

    useEffect(()=>{
        getData()
    },[])

    const generateQR = async (token) => {

        //display Modal
        setShowQr(true)

        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/get-qr/'+token)
            .then(response => {
                //console.log(response.data.data)
                setDisplayQr(response.data.data)
            })
    }

    const deleteInvitado=(id)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-invitado/'+id)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    setShowAlertDelete(true)
                    getData()
                }
            })
        );
    }

    const handleSubmitEdit = async (event) => {

        event.preventDefault();
       
    
        if( inputNombreEdit && inputClaveEdit && inputEventoEdit && inputEmailEdit){
          
          var data_send = {
            "nombre": inputNombreEdit,
            "clave_invitado": inputClaveEdit,
            "email": inputEmailEdit,
            "id_evento": inputEventoEdit,
            "id_horario": inputHorarioEdit,
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
            instance.put('/api/update-invitado/'+inputIdEdit, data_send)
            .then(response => {
                //console.log(response)
                if(response.data.status === "200"){
                    // setInputNombreEdit("");
                    // setInputExistenciasEdit("");
                    setShow(false)
                    setShowAlert(true)
                    getData()
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
    
        }
        else{
          //return error
          alert("Faltan datos"+inputNombreEdit);
        }
        
    }

    const handleSubmit = async (event) =>{

        event.preventDefault();
        setStatusBtnSubmit(true)

        if(showHorariosDiv){
            
            if(inputNombre && inputClave && inputEvento && inputHorario){
                
                var data_send2 = {
                    "nombre": inputNombre,
                    "clave_invitado": inputClave,
                    "email": inputEmail,
                    "id_evento": inputEvento,
                    "id_horario": inputHorario,
                    "status": 0
                }
            
                let ud = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);
            
                const instance = axios.create({
                        baseURL: '',
                        timeout: 12000,
                        headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                        });
                    
                instance.post('api/add-invitado', data_send2)
                .then(response => {
        
                    if(response.data.status === "200"){
                        setStatusBtnSubmit(false)
                        setInputNombre("");
                        setInputClave("");
                        setInputEvento("");
                        setInputEmail("");
                        setInputHorario("");
                        setShowAdd(false)
                        setShowAlert(true)
                        getData()
                    }
                    else{
                        alert("No se tiene capacidad en este horario")
                    }
                })
                .catch(function (error) {
                
                        if(error.response.status === "401"){
                        console.log("se venció el token, please update it")
                        window.location.replace("./refresh-token");
                        
                        }
                });

            }
            else
                alert("Error #3435, [ Faltan datos ]"+inputNombre + inputClave + inputEvento);
        }
        else{
            if(inputNombre && inputClave && inputEvento ){
                
                var data_send = {
                    "nombre": inputNombre,
                    "email": inputEmail,
                    "clave_invitado": inputClave,
                    "id_evento": inputEvento,
                    "status": 0
                }
            
                let ud = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);
            
                const instance = axios.create({
                        baseURL: '',
                        timeout: 12000,
                        headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                        });
                    
                instance.post('api/add-invitado', data_send)
                .then(response => {
                    
                    if(response.data.status === "200"){
                        setInputNombre("");
                        setInputClave("");
                        setInputEvento("");
                        setInputHorario("");
                        setStatusBtnSubmit(false)
                        setShowAdd(false)
                        setShowAlert(true)
                        setTimeout(() => {
                            console.log("Waiting...");
                          }, 2000);
                        getData()
                    }
                    else{
                        alert("El horario seleccionado ya no tiene capacidad")
                    }
                })
                .catch(function (error) {
                    
                    console.log(error);

                        if(error.response.status === "401"){
                        console.log("se venció el token, please update it")
                        window.location.replace("./refresh-token");
                        
                        }
                });

            }
            else
                alert("Error #3435, [ Faltan datos ]"+inputNombre + inputClave + inputEvento);
                setStatusBtnSubmit(false)
        }
    }

    const randomPassword = async () => {
       var pwd = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);
       setInputClave(pwd)
    }
    
    return (
        <>
        <FaEdit onClick={() => handleShowAdd()}  />

        <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
            El invitado se guardó exitosamente
        </Alert>

        <Alert className="mt-4" key="danger" variant="danger" show={showAlertDelete} onClose={() => setShowAlertDelete(false)} dismissible>
            El invitado se eliminó exitosamente
        </Alert>

        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Clave</th>
                    <th>Email</th>
                    <th>Evento</th>
                    <th>Fecha evento</th>
                    <th>Venue</th>
                    <th>Status</th>
                    <th>Fecha acceso</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
            {
                items && items.length>0 && items.map((item)=> {
                    
                    var fecha_ok = item.fecha_evento

                    var today = new Date(fecha_ok);
                    var year = today.getFullYear();
                    var mes = today.getMonth()+1;
                    var dia = today.getDate();
                    var fecha =dia+"-"+mes+"-"+year;
                    var fecha_acceso = ""

                    if(item.fecha_acceso != null){
                        var today2           = new Date(item.fecha_acceso);
                        var year2            = today2.getFullYear();
                        var mes2             = today2.getMonth()+1;
                        var dia2             = today2.getDate();
                        var hora2            = today2.getHours();
                        var minutos2         = today2.getMinutes();
                        var segundos2        = today2.getSeconds();
                        fecha_acceso    = dia2+"-"+mes2+"-"+year2+" "+hora2+":"+minutos2+":"+segundos2;

                    }
                    else{
                        fecha_acceso = ""
                    }

                    var txt_status = ""

                    if(item.i_status === 1)
                        txt_status = "Utilizado"
                    else
                         txt_status = "Activo"

                    return (
                    <tr>
                        <td>{item.idi}</td>
                        <td>{item.nombre_completo}</td>
                        <td>{item.clave_invitado}</td>
                        <td>{item.email}</td>
                        <td>{item.nombre}</td>
                        <td>{fecha}</td>
                        <td>{item.nombre_venue}</td>
                        <td>{txt_status}</td>
                        <td>{fecha_acceso}</td>
                        <td><FaEdit onClick={() => handleShow(item.idi)}  /> <TiDelete onClick={() => handleShowDelete(item.idi)} /> <FaEdit onClick={() => generateQR(item.clave_invitado)}  /></td>
                    </tr>
                    )
                }
                )
            }
            </tbody>
        </Table>

        <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                <Modal.Title>Agregar Invitado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_nombre">Nombre completo</Form.Label>
                        <Form.Control 
                        id="input_nombre"
                        type="text" 
                        placeholder="Nombre completo"
                        // defaultValue={}
                        onKeyUp={(e) => setInputNombre(e.target.value)}
                        required />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_nombre">Evento</Form.Label>
                        <Form.Select aria-label="Seleccione..." onChange={(e) => { setInputEvento(e.target.value); getDataHorarios(e.target.value); }}>
                             <option>Seleccione...</option>
                            {
                                selectEventos.map((item)=> 
                                    (<option value={item.ide}>{item.nombre}</option>))
                            }
                        </Form.Select>
                    </Form.Group>
                    {
                        showHorariosDiv && (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Horario</Form.Label>
                                <Form.Select aria-label="Seleccione..." onChange={(e) => setInputHorario(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectHorarios.map((item)=> {
                                            if(item.asignados<item.capacidad) {
                                                return <option value={item.idh}>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                            else{
                                                return <option value={item.idh} disabled>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" onKeyUp={(e) => setInputEmail(e.target.value)} placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Clave invitado</Form.Label>
                        <Form.Control type="text" defaultValue={inputClave} onKeyUp={(e) => setInputClave(e.target.value)} placeholder="Clave invitado" />
                    </Form.Group>

                    <Button variant="primary" type="submit" onClick={(handleSubmit)} disabled={statusBtnSubmit}>
                        Guardar invitados
                    </Button>
                
                </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar Invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    inputStatusEdit<1 ? (
                        <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_nombre">Nombre completo</Form.Label>
                            <Form.Control 
                            id="input_nombre"
                            type="text" 
                            placeholder="Nombre completo"
                            defaultValue={inputNombreEdit}
                            onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                            required />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_nombre">Evento</Form.Label>
                            <Form.Select aria-label="Seleccione..." value={inputEventoEdit} onChange={(e) => {setInputEventoEdit(e.target.value);  getDataHorariosEdit(e.target.value); }  }>
                                <option>Seleccione...</option>
                                {
                                    selectEventos.map((item)=> 
                                        (<option value={item.ide}>{item.nombre}</option>))
                                }
                            </Form.Select>
                        </Form.Group>

                        {
                        showHorariosDivEdit && (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Horario</Form.Label>
                                <Form.Select aria-label="Seleccione..." value={inputHorarioEdit} onChange={(e) => setInputHorarioEdit(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectHorariosEdit.map((item)=>
                                            (<option value={item.id}>{item.hora_inicial} - {item.hora_fin}</option>))
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Clave invitado</Form.Label>
                            <Form.Control type="text" defaultValue={inputClaveEdit} onKeyUp={(e) => setInputClaveEdit(e.target.value)} placeholder="Clave invitado" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" defaultValue={inputEmailEdit} onKeyUp={(e) => setInputEmailEdit(e.target.value)} placeholder="Email" />
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={(handleSubmitEdit)}>
                            Editar invitado
                        </Button>
                        </>
                    ) :
                    (
                        <>
                            No se puede editar el usuario.
                        </>
                    )
                }
                    
                
                </Modal.Body>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este invitado?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showQr} onHide={handleCloseQr}> 
            <Modal.Header closeButton>
            <Modal.Title>QR invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            { displayQr ? (
                    <>
                    <img src={displayQr} alt="QR invitado" className='QR_main' />
                    </>
                ):
                (
                    <>
                      No se cargó el código QR.
                    </>
                )
            }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseQr}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
            
        </>
    );
}

export default TableVenues;