// import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Welcome from "./pages/Welcome";
import NoPage from "./pages/NoPage";
import Productos from "./pages/Productos";
import Usuarios from "./pages/Usuarios";
import MiCuenta from "./pages/MiCuenta";
import Lector from "./pages/Lector";
import LectorFisico from "./pages/LectorFisico";
import Venues from "./pages/Venues";
import Eventos from "./pages/Eventos";
import Invitados from "./pages/Invitados";
import Logout from "./pages/Logout";
import Login from "./components/login/Login";
// import { useState } from "react";
// import axios from 'axios';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//data will be the string we send from our server

const router = createBrowserRouter([
  { path: "*", element: <Root /> },
]);


export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="productos" element={<Productos />} />
          <Route path="usuarios" element={<Usuarios />} />
          <Route path="my-account" element={<MiCuenta />} />
          <Route path="lector" element={<Lector />} />
          <Route path="lector-fisico" element={<LectorFisico />} />
          <Route path="venues" element={<Venues />} />
          <Route path="eventos" element={<Eventos />} />
          <Route path="invitados" element={<Invitados />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>   
    </>
  );
}

